
import { defineComponent, watch, onMounted } from 'vue'
import { useDelivery } from '@/composables/useDelivery'
import { useGeolocation } from '@/composables/useGeolocation'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const { coords, fetchCoordinates } = useGeolocation()
        const {
            activeDeliveryStage,
            fetchDeliveryListCourier,
            deliveryList,
            deliveryStages,
            fetchDeliveryStages,
            setActiveDeliveryStage,
            isLoading,
        } = useDelivery()

        const coordinates = coords.value

        fetchDeliveryStages()
        const router = useRouter()

        watch(coordinates, () => {
            fetchDeliveryListCourier()
        })

        onMounted(() => {
            fetchCoordinates()
            fetchDeliveryListCourier()
        })

        const changeSelectedDeliveryStage = (event: Event) => {
            const selectedCarWashId = parseInt((event.target as HTMLInputElement).value)
            setActiveDeliveryStage(selectedCarWashId)
            fetchDeliveryListCourier()
        }

        return {
            activeDeliveryStage,
            deliveryList,
            deliveryStages,
            router,
            isLoading,
            changeSelectedDeliveryStage,
        }
    },
})
